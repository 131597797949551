import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ReactPlayer from 'react-player'

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

import { Helmet } from 'react-helmet';

const SchemaOrg = () => {
	const schema = {
  "@context": "https://schema.org",
  "headline": "SEO Frequently Asked Questions",
  "author": {
    "@type": "Person",
    "name": "J. Ryan Williams"
  },
  "datePublished": "2024-06-02",
  "publisher": {
    "@type": "Organization",
    "name": "The Websuasion Group LLC", 
    "url": "https://websuasion.com",
    "logo": {
      "@type": "ImageObject",
      "url": "https://websuasion.com/images/websuasion-full-logo.png"
    }
  },
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Where does SEO fit into the list of priorities for a marketing plan?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Keyword Research should be the foundation of any marketing plan—even if you never do content marketing. Understanding the search intent of your ideal customer will inform every aspect of your messaging. Content Marketing should be the most aggressive ongoing aspect of marketing, particularly if you can produce quality content internally. There is no better ROI."
      }
    },
    {
      "@type": "Question",
      "name": "What are the myths around SEO?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SEO means 'Google'\nGoogle tells us the truth\nresults are 100% predictable\nyou can get real results quickly\nsearch is dead"
      }
    },
    {
      "@type": "Question",
      "name": "Which voices do we trust as an authority in the space?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Many great people and agencies are in the space, but there are many more shady folks. Avoid anyone making income claims or traffic guarantees. Also, understand that this is a very 'hypothesize and test' industry because we constantly work against 'black boxes' and companies trying to mislead us. So, nobody is 100% right all the time. Don't give an individual's or company's perspective too much weight. Do take note when people provide demonstratable results.\nSome blogs that I like:\nhttps://moz.com/blog\nhttps://www.semrush.com/blog/\nhttps://www.searchenginejournal.com/"
      }
    },
    {
      "@type": "Question",
      "name": "What's the simplest way to explain SEO?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "It's using reliable data to determine the search intent of your ideal customer. You then design content with search algorithms in mind to reach and ultimately convert those searches into purchases."
      }
    },
    {
      "@type": "Question",
      "name": "Is SEO a black art? Or is there a step-by-step process to break down an SEO strategy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Anything with SEO that could be considered 'black art' is also probably a bad idea to be doing. Gaming the system will ultimately come back to bite you. The strategy is researchable, the content development strategic, and the results measurable. Organic results will take time. So, it's best to stick with it for a year to see results. Start with a robust keyword research plan. Next, in Q1, you focus on pillar page development based on what you determine your top keywords to be. In Q2, you will work on legitimate backlinks to those pages. In Q3, you will find ways to increase the time on each page. Then, in Q4, you will focus on increasing conversions."
      }
    },
    {
      "@type": "Question",
      "name": "How much weight do we give to using tools for research vs. interviewing actual customers?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Interviewing customers is anecdotal. So, be careful not to put too much weight on their input without further validation. You can get great insights from customer interaction. However, even when they tip you off on issues or ideas, you still need to measure the validity of it. Keyword Research wins the day here. Robust keyword research will provide you with a clear understanding of your ideal customer's pain points and search intent. And that will apply fairly consistently whether they search on Google, Bing, YouTube, TikTok, Pinterest, Meta, etc."
      }
    },
    {
      "@type": "Question",
      "name": "What do they mean by on-page SEO vs technical SEO?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "On-page SEO is anything that happens on your website over which you (should) have 100% control. This is the converse of Off-page SEO, which has more to do with backlinks and is more akin to Public Relations activity than Content Development.\nTechnical SEO is about how a page performs technically: how fast it loads, how well it handles accessibility issues, how it performs on mobile, and how quickly it accesses external assets like images, fonts, tracking scripts, etc.\nTechnical SEO is an aspect of On-page SEO because it is something you directly control on your site."
      }
    },
    {
      "@type": "Question",
      "name": "Is it a set-and-forget process?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Nothing is 'set and forget' in marketing. Things continue to change, so you must modify content to take advantage. About 20 pages at a time get the priority focus with Google, so it's a good strategy to keep content up to date or expand upon content on the 20 pages that perform best for you. Once you have those 20 pages updated, update others based on their age."
      }
    }
  ]
};
	
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

const IndexPage = () => {
  return (
    <PageLayout>
			<SchemaOrg />
      <Seo
        title="SEO Frequently Asked Questions - Websuasion"
        ogDescription="These are some great questions about SEO that business owners ask - and our answers."
        image="/images/seo-frequently-asked-questions.webp"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Frequently Asked Questions About Search Engine Optimization (SEO)</h1>
					
						<p>
						   Look, we understand—SEO can be confusing. It's a complex blend of strategy, positioning, content development, measurement, and constant tweaking. Naturally, people have a lot of questions. We're committed to expanding this page with more answers as new questions arise. Stay tuned for updates!
						</p>

						<p>
						   A big thank you to <a href="https://www.threads.net/@cass.dowding" target="_blank" rel="noreferrer">Cass Dowding</a> for creating the initial list of questions and inspiring this FAQ.
						</p>

						<p>
						   Have more questions about SEO? Feel free to reach out. We're here to help! And be sure to check out our free webinar on <a href="https://websuasion.easywebinar.live/white-label-keyword-research-registration" traget="_blank">How Robust Keyword Research Can Transform Your Clients' Marketing Efforts</a>, which goes into greater detail on many of these points.
						</p>

		        <FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
		      <div className='player-wrapper'>
		        <ReactPlayer
		          className='react-player'
		          url='https://www.youtube.com/watch?v=f5HjxhRWHfk'
		          width='100%'
		          height='100%'
		        />
		      </div>
        }
      />
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/seo-frequently-asked-questions.webp" className="rounded-image" alt="SEO Frequently Asked Questions - Websuasion" />
	          </div>
	        }
	        textRight={
			      <div>
							<h2>Where does SEO fit into the list of priorities for a marketing plan?</h2>

							<p>
								Keyword Research should be the foundation of any marketing plan—even if you never do content marketing. Understanding the search intent of your ideal customer will inform every aspect of your messaging. Content Marketing should be the most aggressive ongoing aspect of marketing, particularly if you can produce quality content internally. There is no better ROI.
							</p>
				
							<h2>What are the myths around SEO?</h2>

							<ul>
								<li>SEO means "Google"</li>
								<li>Google tells us the truth</li>
								<li>results are 100% predictable</li>
								<li>you can get real results quickly</li>
								<li>search is dead</li>
							</ul>
			      </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Which voices do we trust as an authority in the space?</h2>

							<p>
								Many great people and agencies are in the space, but there are many more shady folks. Avoid anyone making income claims or traffic guarantees. Also, understand that this is a very "hypothesize and test" industry because we constantly work against "black boxes" and companies trying to mislead us. So, nobody is 100% right all the time. Don't give an individual's or company's perspective too much weight. Do take note when people provide demonstratable results.
							</p>
				
							<p>
								Some blogs that we like: 
							</p>

							<ul>
							<li><a href="https://moz.com/blog">https://moz.com/blog</a></li>
							<li><a href="https://www.semrush.com/blog/">https://www.semrush.com/blog/</a></li>
							<li><a href="https://www.searchenginejournal.com/">https://www.searchenginejournal.com/</a></li>
							</ul>


							<h2>What's the simplest way to explain SEO?</h2>

							<p>
								It's using reliable data to determine the search intent of your ideal customer. You then design content with search algorithms in mind to reach and ultimately convert those searches into purchases.
							</p>
					
							<h2>Is SEO a black art? Or is there a step-by-step process to break down an SEO strategy?</h2>

							<p>
								Anything with SEO that could be considered "black art" is also probably a bad idea to be doing. Gaming the system will ultimately come back to bite you. The strategy is researchable, the content development strategic, and the results measurable. Organic results will take time. So, it's best to stick with it for a year to see results. Start with a robust keyword research plan. Next, in Q1, you focus on pillar page development based on what you determine your top keywords to be. In Q2, you will work on legitimate backlinks to those pages. In Q3, you will find ways to increase the time on each page. Then, in Q4, you will focus on increasing conversions. 
							</p>
					
							<h2>How much weight do we give to using tools for research vs. interviewing actual customers?</h2>
					
							<p>
								Interviewing customers is anecdotal. So, be careful not to put too much weight on their input without further validation. You can get great insights from customer interaction. However, even when they tip you off on issues or ideas, you still need to measure the validity of it. Keyword Research wins the day here. Robust keyword research will provide you with a clear understanding of your ideal customer's pain points and search intent. And that will apply fairly consistently whether they search on Google, Bing, YouTube, TikTok, Pinterest, Meta, etc.
							</p>
					
							<h2>What do they mean by on-page SEO vs technical SEO?</h2>

							<p>
								On-page SEO is anything that happens on your website over which you (should) have 100% control. This is the converse of Off-page SEO, which has more to do with backlinks and is more akin to Public Relations activity than Content Development.
							</p>
							<p>
								Technical SEO is about how a page performs technically: how fast it loads, how well it handles accessibility issues, how it performs on mobile, and how quickly it accesses external assets like images, fonts, tracking scripts, etc.
							</p>
							<p>
								Technical SEO is an aspect of On-page SEO because it is something you directly control on your site.
							</p>
					
							<h2>Is it a set-and-forget process?</h2>
					
							<p>
								Nothing is "set and forget" in marketing. Things continue to change, so you must modify content to take advantage. About 20 pages at a time get the priority focus with Google, so it's a good strategy to keep content up to date or expand upon content on the 20 pages that perform best for you. Once you have those 20 pages updated, update others based on their age.
							</p>
	          </div>
	        }
	      />
		</PageWrapper>
    <PageWrapper color="white">				
			<TextBlock
        textPadded
        textLeft={
          <div>
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/watch?v=-YTR647HTBM'
			          width='100%'
			          height='100%'
			        />
						</div>
          </div>
        }
        textRight={
          <div>
            <h4>Let's Talk!</h4>
            <p>Websuasion combines data analytics, cutting-edge tech, and expert teams. "Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <RequestMoreInfo />
          </div>
        }
      />
		</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage